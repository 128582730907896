var api = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../node_modules/css-loader/index.js??ref--1-1!../../node_modules/postcss-loader/dist/cjs.js!../../node_modules/string-replace-webpack-plugin/loader.js?id=gyhjvmecgjr!../../node_modules/sass-loader/dist/cjs.js??ref--1-4!./index.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"injectType":"singletonStyleTag","attributes":{"id":"online-chat-root-styles"}};

options.insert = function insertAtTop(element) {
                                    var parent = document.querySelector('head');
                                    if (parent) {
                                        var firstElement = parent.firstElementChild;
                                        if (firstElement) {
                                            parent.insertBefore(element, firstElement);
                                        }
                                        else {
                                            parent.appendChild(element);
                                        }
                                    }
                                };
options.singleton = true;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;